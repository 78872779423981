<script>
import { get, post } from "../plugins/axios";
import axios from "axios";
import $ from "jquery";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      radioGroup: null,
      siteKey: "6LeFYQodAAAAAM9b6qk9eMnI564u6p_MuUxCgHn5", //euromedi   , //// => localhost : "6LfQvAEkAAAAAIEine_SbREpchqDwHpVOYTghux4" // => remote : "6Lcy7skpAAAAAJ1BzwJRvVFlbD0Wixk7JeNcdzvS"
      isCaptchaSuccessful: false,
      loading: false,
      dialog: false,
      err: false,
      cats: [],
      required: (v) => !!v || this.$t("required"),
      email: (v) => /.+@.+\..+/.test(v) || "E-mail invalide",
    };
  },
  beforeMount() {
    this.getCat();
  },
  methods: {
    onCaptchaVerify(token) {
      this.isCaptchaSuccessful = true;
      // Provide the token response to the form object.
      this.$refs.form["g-recaptcha-response"] = token;
    },
    onCaptchaExpired() {
      this.isCaptchaSuccessful = false;
    },
    handleCaptchaError(token) {
      this.isCaptchaSuccessful = false;
    },
    async submit() {
      // this.$refs.recaptcha.execute();   //Only with invisible recaptcha
      if (this.$refs.form.validate() && this.isCaptchaSuccessful) {
        this.err = false;
        this.loading = true;
        let payload = {};
        let ret = {};
        $("form")
          .serializeArray()
          .forEach((e) => (payload[e.name] = e.value));
        try {
          // (async function () {
          const { data } = await post("/api/register", payload);

          this.$router.push(
            `/${payload.category_id}?token=${data.token}&inst=${data.institution_id}`
          );
          console.log("ret=", ret);

          this.loading = false;
          // this.$router.push("/validated");
          // })();
        } catch (e) {
          this.loading = false;
          this.dialog = true;
          this.err = true;
          console.log("err=", e);
        }
      }
      // }
    },

    async getCat() {
      this.loading = true;
      try {
        const { data } = await get("/api/category");
        this.cats = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<template>
  <!-- {{ process.env.RECAPTCHAV2_SITEKEY }} -->
  <v-form @submit.prevent="submit" ref="form">
    <v-row justify="center">
      <v-col lg="9">
        <h2 class="mb-3">
          {{ $t("title") }}
        </h2>
        <p>
          {{ $t("desc") }}
        </p>
        <h3 class="mb-2">
          {{ $t("sub-title") }}
        </h3>
        <v-card :disabled="loading" :loading="loading" elevation="2" outlined>
          <div style="padding: 20px">
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  :rules="[required]"
                  :label="$t('instName')"
                  filled
                  name="name"
                ></v-text-field
              ></v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :label="$t('website')"
                  name="website"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[required]"
                  :label="$t('nationality')"
                  name="country"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[required]"
                  :label="$t('lastname')"
                  name="lastname"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[required]"
                  :label="$t('firstname')"
                  name="firstname"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[required]"
                  :label="$t('function')"
                  name="position"
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  :rules="[required, email]"
                  :label="$t('E-mail')"
                  name="email"
                  filled
                ></v-text-field>
              </v-col>

              <v-col lg="12">
                <b>{{ $t("label1") }}:</b>
                <p>
                  {{ $t("desc1") }}
                </p>
                <v-radio-group :rules="[required]" v-model="radioGroup">
                  <v-radio
                    name="category_id"
                    v-for="cat of cats"
                    :key="cat.id"
                    :value="cat.id"
                    :label="$i18n.locale == 'en' ? cat.name_en : cat.name"
                  ></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <v-checkbox
                  :rules="[required]"
                  :label="$t('terms&conditions')"
                ></v-checkbox>

                <!-- size="invisible" -->
                <vue-recaptcha
                  ref="recaptcha"
                  :sitekey="siteKey"
                  :language="$i18n.locale"
                  @verify="onCaptchaVerify"
                  @error="handleCaptchaError"
                  @expired="onCaptchaExpired"
                ></vue-recaptcha>

                <!-- <span v-if="recaptcha_error" key="" class="error-message">
                  Please confirm you are not a robot.
                </span> -->

                <v-btn
                  class="mt-3"
                  :disabled="!isCaptchaSuccessful"
                  type="submit"
                  color="primary"
                  >{{ $t("NextStep") }}</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title v-if="!err" class="text-h5 success lighten-3">
          Bien recu!
        </v-card-title>
        <v-card-title v-else class="text-h5 red lighten-3"> Oops </v-card-title>

        <v-card-text v-if="!err" style="padding: 20px">
          Nous avons bien enregistré votre demande, vous allez recevoir un
          E-mail contenant un lien vers l'étape suivante.
        </v-card-text>

        <v-card-text v-else style="padding: 20px">
          Une erreur s'est produite, veuillez contacter l'administration ou
          réessayer
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<style></style>
